import React from 'react';
import { HiCheck, HiX } from 'react-icons/hi';
import dateFormat from 'dateformat';

export const buildTitle = (info) => {
  const { title } = info.row.original;
  return <div className="py-1 justify-start w-300 truncate">{title}</div>;
};

export const buildNotes = (info) => {
  const { notes } = info.row.original;
  return <div className="justify-start w-500 truncate">{notes}</div>;
};

export const buildDate = (date) => (
  <div className="flex justify-center w-100">
    {date ? dateFormat(date, 'dd/mm/yyyy') : '-'}
  </div>
);

export const buildDateTime = (date) => (
  <div className="flex justify-center w-100">
    {date ? dateFormat(date, 'dd/mm/yyyy HH:MM:ss') : '-'}
  </div>
);

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const buildCurrency = (value) => (
  <div className="flex justify-center w-100">
    {numberWithCommas((value / 100).toFixed(2).toLocaleString())}
  </div>
);

export const buildBoolean = (value) => (
  <div className="flex justify-center items-center">
    {value ? (
      <HiCheck className="text-platform-cta-success-800" />
    ) : (
      <HiX className="text-platform-cta-error-800" />
    )}
  </div>
);

export const buildStatus = (info) => {
  const { status } = info.row.original;
  return (
    <div className="flex justify-center">
      <span
        className={`px-3 uppercase leading-wide font-bold text-xs rounded-full shadow-sm
          ${
            status.startsWith('Blocked')
              ? 'bg-platform-cta-error-200 text-platform-cta-error-600'
              : status.startsWith('Deleted')
              ? 'bg-platform-cta-error-200 text-platform-cta-error-600'
              : status.startsWith('Delivered')
              ? 'bg-platform-cta-success-200 text-platform-cta-success-600'
              : status.startsWith('Potential')
              ? 'bg-platform-secondary-orange-200 text-platform-secondary-orange-800'
              : status.startsWith('Planned')
              ? 'bg-platform-primary-blue-200 text-platform-primary-blue-800'
              : status.startsWith('Duplicate')
              ? 'bg-platform-primary-purple-200 text-platform-primary-purple-800'
              : status.startsWith('Abandoned')
              ? 'bg-platform-cta-error-200 text-platform-cta-error-600'
              : null
          }`}>
        {status}
      </span>
    </div>
  );
};

export const buildFieldFromId = (id, dataArray) => (
  <div>{dataArray.filter((x) => x.id === id).map((y) => y.name)}</div>
);
